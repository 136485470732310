import {createStore} from "effector";
import DashboardEntity from "../entities/DashboardEntity";
import {FetchRequestFx} from "../effects/FetchRequestFx";
import OnStartFetchRdc from "../reducers/OnStartFetchRdc";
import DashboardProps from "../props/DashboardProps";
import FetchDataRdc from "../reducers/FetchDataRdc";
import ChangeTypeRdc from "../reducers/ChangeTypeRdc";
import OnChangeDashboardTypeEvt from "../events/OnChangeDashboardTypeEvt";
import DashboardGate from "../gates/DashboardGate";

export default function DashboardStore(props: DashboardProps) {
    const store = createStore<DashboardEntity>({
        isLoading: true,
        type: props.type,
        series: []
    })

    const fetchRequestFX = FetchRequestFx(props.repo)

    const fetchDataRdc = FetchDataRdc()
    const changeTypeRdc = ChangeTypeRdc()
    const onStartFetchRdc = OnStartFetchRdc()

    const onChangeDashboardTypeEvt = OnChangeDashboardTypeEvt()

    const gate = DashboardGate

    // Events
    store.on(gate.open, onStartFetchRdc)
    store.on(onChangeDashboardTypeEvt, changeTypeRdc)

    store.watch(gate.open, fetchRequestFX)
    store.watch(onChangeDashboardTypeEvt, fetchRequestFX)

    store.on(fetchRequestFX.doneData, fetchDataRdc)

    store.watch((state) => {
        console.log(state)
    })


    return {
        gate: DashboardGate,
        store: store,
        fetchRequestFX: fetchRequestFX,
        fetchDataRdc: fetchDataRdc,
        changeTypeRdc: changeTypeRdc,
        onStartFetchRdc: onStartFetchRdc,
        onChangeDashboardTypeEvt: onChangeDashboardTypeEvt

    }
}

