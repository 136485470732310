import DashboardEntity from "../entities/DashboardEntity";

function FetchDataRdc() {
    const rdc = (
        state: DashboardEntity,
        payload: {title: string, value: number}[]
    ) => {
        return {
            ...state,
            isLoading: false,
            series: payload
        }
    }

    return rdc
}

export default FetchDataRdc