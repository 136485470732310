import React from 'react';
import {Box} from "@material-ui/core";
import {FTLLoader, FTLNonAxisChart, FTLPageHeader, FTLSegmentedControl, FTLSegmentedControls} from "ftl-uikit";
import DashboardStyles from "../styles/DashboardStyles";
import DashboardStore from "../../../../../domains/dashboard/Dashboard/stores/DashboardStore";
import DashboardType from "../../../../../domains/dashboard/Dashboard/entities/DashboardType";
import DashboardMockRepository from "../../../../../infrastructure/mock/dashboard/DashboardMockRepository";
import {useGate, useStore} from "effector-react";
import DashboardApiRepository from "../../../../../infrastructure/api/dashboard/DashboardApiRepository";

const dashboardStore = DashboardStore({
    type: DashboardType.PRICE,
    repo: DashboardApiRepository,
    //repo: DashboardMockRepository
})

function DashboardPage() {
    const classes = DashboardStyles();

    const store = useStore(dashboardStore.store)
    useGate(dashboardStore.gate, DashboardType.PRICE)

    return (
        <div className={classes.root}>
            <Box marginBottom="auto">
                <FTLPageHeader title={`Products in categories`} BoxProps={
                    {
                        mb: 6
                    }
                }/>
                <Box display="grid" mt={6} gridGap="24px" width={'360px'}>
                    <FTLSegmentedControls
                        onChange={(e, value) => {
                            dashboardStore.onChangeDashboardTypeEvt(value == 0 ? DashboardType.PRICE : DashboardType.COUNT)
                        }}
                        value={store.type.valueOf()}>
                        <FTLSegmentedControl disabled={store.isLoading} label="Show prices"
                                             value={DashboardType.PRICE.valueOf()}/>
                        <FTLSegmentedControl disabled={store.isLoading} label="Show count"
                                             value={DashboardType.COUNT.valueOf()}/>
                    </FTLSegmentedControls>
                </Box>
                <Box display="grid" mt={6} gridGap="24px" width={'500px'}>
                    {store.isLoading ?
                        <FTLLoader/>
                        : <FTLNonAxisChart type={"pie"} height={'360px'}
                                           series={store.series.map(item => item.value)}
                                           labels={store.series.map(item => item.title)}
                        />}
                </Box>

            </Box>

        </div>
    );
}

export default DashboardPage;
