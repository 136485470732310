import React, {lazy, ReactNode, Suspense} from "react"
import {Route, Switch} from "react-router-dom"
import {ErrorContainer, FTLErrorBoundary, FTLErrorPage, FTLLoader} from "ftl-uikit"
import {errorPageService, history, logOutAction, PrivateRoute} from "ftl-core"
import {
    FTLBaseLayout,
    FTLCredentialDetailPage,
    FTLCredentialListPage,
    FTLMainLayoutErrorBoundaryComponent
} from "ftl-dashboards-templates";
import FTLMainLayoutBaseBoundaryProps
    from "ftl-dashboards-templates/dist/app/layouts/props/FTLMainLayoutBaseBoundaryProps";
import {projectTitle} from "../../infrastructure/constants";
import {Dashboard} from "@material-ui/icons";
import DashboardPage from "../pages/dashboard/DashboardPage/pages/DashboardPage";


//Profile
const ProfilePage = lazy(() => import("ftl-dashboards-templates/dist/app/templates/FTLProfilePage"))
const FTLInitAdminPage = lazy(() => import('ftl-dashboards-templates/dist/app/templates/FTLInitAdminPage'))

/**
 * Category
 */
const CategoryListPage = lazy(() => import('../pages/category/CategoryList/page/CategoryListPage'))
const CategoryDetailPage = lazy(() => import('../pages/category/CategoryDetail/page/CategoryDetailPage'))
const CategoryCreatePage = lazy(() => import('../pages/category/CategoryCreate/page/CategoryCreatePage'))

/**
 * Product
 */
const ProductListPage = lazy(() => import('../pages/product/ProductList/page/ProductListPage'))
const ProductDetailPage = lazy(() => import('../pages/product/ProductDetail/page/ProductDetailPage'))
const ProductCreatePage = lazy(() => import('../pages/product/ProductCreate/page/ProductCreatePage'))


const ReactDefaultPage = lazy(() => import('../pages/ReactDefaultPage/pages/ReactDefaultPage'))

export const Routes = ({
                           AuthClient,
                           toHomeUrl,
                           project,
                           StoreProviderProps,
                           children,
                           BaseLayoutProps
                       }: FTLMainLayoutBaseBoundaryProps) => {
    const BaseLayout = (title: string, childrenPage: ReactNode) => {
        return (<Suspense fallback={<FTLLoader height="50vh" color="dark"/>}>
            <FTLBaseLayout
                AuthClient={AuthClient}
                toHomeUrl={toHomeUrl}
                title={title}
                onSignOut={() =>
                    StoreProviderProps.store.dispatch(logOutAction)
                }
                logo={project.logo?.header}
                {...BaseLayoutProps}
            >
                <FTLErrorBoundary location={history.location}>
                    <ErrorContainer basePath={toHomeUrl}>
                        <ErrorContainer
                            service={errorPageService}
                            basePath={toHomeUrl}
                        >
                            {childrenPage}
                        </ErrorContainer>
                    </ErrorContainer>
                </FTLErrorBoundary>
            </FTLBaseLayout>
        </Suspense>)
    }

    return (
        <Switch>
            <PrivateRoute exact path={"/dashboard"}>
                {/*{BaseLayout(projectTitle, <div>Welcome to FoodTech ES!</div>)}*/}
                {BaseLayout(projectTitle, <DashboardPage/>)}
            </PrivateRoute>

            <PrivateRoute exact path={"/credential"}>
                {BaseLayout(projectTitle, <FTLCredentialListPage></FTLCredentialListPage>)}
            </PrivateRoute>
            <PrivateRoute exact path={"/credential/:id"}>
                {BaseLayout(projectTitle, <FTLCredentialDetailPage></FTLCredentialDetailPage>)}
            </PrivateRoute>

            <PrivateRoute exact path={"/category"}>
                {BaseLayout(projectTitle, <CategoryListPage/>)}
            </PrivateRoute>
            <PrivateRoute exact path={"/category/new"}>
                {BaseLayout(projectTitle, <CategoryCreatePage/>)}
            </PrivateRoute>
            <PrivateRoute exact path={"/category/:id"}>
                {BaseLayout(projectTitle, <CategoryDetailPage/>)}
            </PrivateRoute>

            <PrivateRoute exact path={"/product"}>
                {BaseLayout(projectTitle, <ProductListPage/>)}
            </PrivateRoute>
            <PrivateRoute exact path={"/product/new"}>
                {BaseLayout(projectTitle, <ProductCreatePage/>)}
            </PrivateRoute>
            <PrivateRoute exact path={"/product/:id"}>
                {BaseLayout(projectTitle, <ProductDetailPage/>)}
            </PrivateRoute>

            <Route exact path="/init-admin">
                <FTLMainLayoutErrorBoundaryComponent>
                    <Suspense fallback={<FTLLoader height="50vh" color="dark"/>}>
                        <FTLInitAdminPage redirectUrl={'/dashboard'}/>
                    </Suspense>
                </FTLMainLayoutErrorBoundaryComponent>
            </Route>

            <PrivateRoute exact path={["*", "/404"]}>
                <FTLErrorPage
                    title="Страницы не существует"
                    message="Наверное, вы перешли по неправильной ссылке."
                />
            </PrivateRoute>
        </Switch>
    )
}
