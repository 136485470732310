import DashboardRepository from "../../../domains/dashboard/Dashboard/repositories/DashboardRepository";
import DashboardType from "../../../domains/dashboard/Dashboard/entities/DashboardType";
import {API_BASE_URL} from "ftl-dashboards-templates/dist/infrastructure/api/auth/routers/AuthRouter";
import {ListResponse} from "ftl-core";
import CategoryListEntity from "../../../domains/category/CategoryList/entity/CategoryListEntity";
import BaseApiRepository from "../base/BaseApiRepository";
import ProductListEntity from "../../../domains/product/ProductList/entity/ProductListEntity";

const DashboardApiRepository: DashboardRepository = {
    fetchData: (type: DashboardType) => {
        return new Promise<{ title: string, value: number }[]>(async (resolve) => {

            let result = []

            let urlCategory = `${API_BASE_URL}/api/v1/category?limit=1000&offset=0`;
            let urlProducts = `${API_BASE_URL}/api/v1/product?limit=1000&offset=0`;

            const categories = await BaseApiRepository.get<ListResponse<CategoryListEntity>>(urlCategory)
            const products = await BaseApiRepository.get<ListResponse<ProductListEntity>>(urlProducts)

            for (let category of categories.data.result.items) {
                let price = 0, count = 0
                for (let product of products.data.result.items) {
                    if (product.categoryId == category.id) {
                        count += 1
                        price += product.price
                    }
                }
                result.push({
                    title: category.title,
                    value: type == DashboardType.PRICE ? price : count
                })
            }
            resolve(result)
        })
    }
}

export default DashboardApiRepository