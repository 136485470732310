import {
    CityIcon,
    CourierIcon,
    FolderIcon,
    MapIcon,
    PeopleIcon,
    theme,
} from "ftl-uikit"

import StoreMallDirectoryIcon from "@material-ui/icons/StoreMallDirectory"

import {Routes} from "./app/routes/Routes"

import {createStore} from "redux"
import {FTLUIMainLayout, UIMain} from "ftl-dashboards-templates"
import {AuthAPI, ProfileAPI} from "./infrastructure/repository"
import {
    basicReducer,
    handleError,
    initialState,
    logOutAction,
    setProfileAction,
} from "ftl-core"
import {projectTitle} from "./infrastructure/constants"
import FTLMainLayoutBaseBoundaryProps
    from "ftl-dashboards-templates/dist/app/layouts/props/FTLMainLayoutBaseBoundaryProps";

const initStateWithPageSize10 = {
    ...initialState,
    globalStore: {...initialState.globalStore, pageSize: 10},
}

const store = createStore(
    basicReducer,
    initStateWithPageSize10,
    //@ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export const App = () => {
    const project = {
        title: projectTitle,
    }
    const redirectOnAuthUrl = "/dashboard"
    const StoreProviderProps = {
        store,
        //@ts-ignore
        getProfile: () => {
        },
    }
    const BaseLayoutProps = {
        onMenuClick: async () => {
            if (!store.getState().globalStore.profile?.id)
                try {
                    const profile = (await ProfileAPI.get()).data.result
                    store.dispatch(setProfileAction(profile))
                } catch (error: any) {
                    handleError({
                        error,
                        defaultError: "Произошла ошибка при получении данных профиля",
                    })
                }
        },
        onSignOut: () => store.dispatch(logOutAction),
        sideMenuItems: [
            {
                icon: <FolderIcon/>,
                label: "Dashboard",
                to: "/dashboard",
            },{
                icon: <FolderIcon/>,
                label: "Category",
                to: "/category",
            },{
                icon: <FolderIcon/>,
                label: "Product",
                to: "/Product",
            },
        ],
    }

    return (
        <FTLUIMainLayout
            project={project}
            redirectOnAuthUrl={redirectOnAuthUrl}
            AuthClient={AuthAPI}
            theme={theme}
            //@ts-ignore
            StoreProviderProps={StoreProviderProps}
            {...BaseLayoutProps}
        >
            <Routes AuthClient={AuthAPI}
                    toHomeUrl={redirectOnAuthUrl}
                    project={{title: {authPage: projectTitle, header: projectTitle}}}
                //@ts-ignore
                    StoreProviderProps={StoreProviderProps}
                    BaseLayoutProps={BaseLayoutProps}/>
        </FTLUIMainLayout>

    )
}

export default App
