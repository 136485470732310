import DashboardEntity from "../entities/DashboardEntity";
import DashboardType from "../entities/DashboardType";


function OnStartFetchRdc() {
    const onStartFetchRdc = (
        state: DashboardEntity,
        payload: DashboardType
    ) => {
        return {
            ...state,
            isLoading: true,
            type: payload
        }
    }

    return onStartFetchRdc
}

export default OnStartFetchRdc
