import {createEffect} from "effector/effector.cjs";
import DashboardRepository from "../repositories/DashboardRepository";
import DashboardEntity from "../entities/DashboardEntity";

export function FetchRequestFx(repo: DashboardRepository) {

    const fetchRequest = createEffect({
        handler: async (state: DashboardEntity) => {
            let result = repo.fetchData(state.type)
            return result
        }
    })

    return fetchRequest
}
