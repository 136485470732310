import DashboardEntity from "../entities/DashboardEntity";
import DashboardType from "../entities/DashboardType";

function ChangeTypeRdc() {
    const rdc = (
        state: DashboardEntity,
        payload: DashboardType
    ) => {
        return {
            ...state,
            isLoading: true,
            type: payload
        }
    }

    return rdc
}

export default ChangeTypeRdc