import {AuthAPI, AxiosRepository} from "ftl-core";
import {API_BASE_URL} from "ftl-dashboards-templates/dist/infrastructure/api/auth/routers/AuthRouter";

const BaseApiRepository = AxiosRepository({
  baseUrl: API_BASE_URL,
  tokenRefresh: () => AuthAPI.refresh(),
  authErrors: {
    reasonKey: "reason",
    tokenExpired: ["ACCESS_TOKEN_EXPIRED"],
    permissionInsufficient: ["CREDENTIAL_PERMISSION_INSUFFICIENT"],
    needToLogin: [
      "CREDENTIAL_BLOCKED",
      "ACCESS_TOKEN_INACTIVATED",
      "ACCESS_TOKEN_NOT_EXIST",
      "ACCESS_TOKEN_REFRESHED",
      "ACCESS_TOKEN_MODIFIED",
      "ACCESS_TOKEN_MALFORMED",
    ],
  }
})
export default BaseApiRepository
