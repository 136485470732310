import {createEvent} from "effector";
import DashboardType from "../entities/DashboardType";

const OnChangeDashboardTypeEvt = () => {
    const event = createEvent<DashboardType>("OnChangeDashboardTypeEvt")
    event.watch(() => console.log("OnChangeDashboardTypeEvt"))
    return event
}

export default OnChangeDashboardTypeEvt
